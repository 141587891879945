import { ProfileDetailsDto } from '@app/models/dtos/infoDto';

function AboutMeCard({ profileDetails }: { profileDetails: ProfileDetailsDto }) {
    return (
        <div className="flex w-full flex-col gap-6 rounded-[8px] bg-red-bg p-6 lg:h-[410px] lg:max-w-[341px]">
            <p className="h3 text-black-100">ABOUT ME</p>
            <p className="p1Responsive text-black-200">{profileDetails.description}</p>
        </div>
    );
}

export default AboutMeCard;
