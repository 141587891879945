import { useModal } from '@app/components/modal-views/context';
import AnchorLink from '@app/components/ui/links/anchor-link';
import useIntersectionObserver from '@app/lib/hooks/use-intersection-observer';
import useIsMobile from '@app/lib/hooks/use-isMobile';
import { ProfileDetailsDto } from '@app/models/dtos/infoDto';
import { Button } from '@mui/material';
import cn from 'classnames';

function ContactCard({ profileDetails, facts }: { profileDetails: ProfileDetailsDto; facts: Array<string> }) {
    const { openModal } = useModal();
    const { isVisible, ref } = useIntersectionObserver({ threshold: 0.3 });
    const { isMobile } = useIsMobile();
    const mailLink = isMobile ? `mailto:${profileDetails.email}` : 'https://mail.google.com/mail/u/0/#inbox?compose=VpCqJRzKDRNCvWfpzrFDnCRzJHVVjVsvLxpwnWsWVBWZRvwGqwWCkPZGXjKmtkSfqhDBKJv';
    return (
        <div ref={ref} className={cn('flex h-[284px] w-full flex-col items-center justify-center rounded-[8px] bg-contactbgsmall bg-no-repeat px-2 md:h-[368px] md:bg-contactbg', isVisible && 'in-view', 'animated-component')}>
            <p className="p1Responsive text-black-200">Feel free to reach out to me</p>
            <AnchorLink href={mailLink} target="_blank" className="h1eResponsive mb-8 mt-2 text-black-100 md:mb-12">
                {profileDetails.email}
            </AnchorLink>
            <Button onClick={() => openModal('RANDOM_FACT_MODAL', { facts })} className="p1Responsive h-[56px] rounded-[8px]  bg-black-200 px-[59px] py-4 font-urbanist normal-case text-white hover:bg-black-100 md:h-[66px]">
                Developer Facts
            </Button>
        </div>
    );
}

export default ContactCard;
