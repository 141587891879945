import ImageRenderer from '@app/components/media-renderer/image-renderer';
import { ProfileDetailsDto } from '@app/models/dtos/infoDto';

function HobbyCard({ profileDetails }: { profileDetails: ProfileDetailsDto }) {
    return (
        <div className="col-auto rounded-[8px] bg-blue-bg p-6 sm:col-span-2 md:p-10 lg:h-[410px]">
            <div className="grid grid-rows-2 gap-[19px] md:max-h-[172px] md:grid-cols-2 md:grid-rows-1">
                <ImageRenderer src={profileDetails.hobbyImage1} className="rounded-[8px]" />
                <ImageRenderer src={profileDetails.hobbyImage2} className="rounded-[8px]" />
            </div>
            <p className="p1Responsive mt-6 text-black-200">{profileDetails.hobby}</p>
        </div>
    );
}

export default HobbyCard;
