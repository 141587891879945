import { useEffect, useState } from 'react';
import Star from './icons/star';
import AnchorLink from './ui/links/anchor-link';

export default function AIBioTopBar() {
    const [isInView, setIsInView] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const aiBioLink = 'https://www.smartbio.me/clvzzyugl0000ogqksz72i4jd';

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsInView(true);
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div
            className={`topBar fixed top-0 z-50 mx-[15px] h-[60px] w-full items-center justify-between space-x-0 rounded-[4px] bg-white px-3 backdrop-blur-[11px] sm:mx-5 sm:justify-center sm:space-x-20 md:h-[82px] lg:mx-10 lg:px-6 ${isScrolled ? 'fade-in-down' : 'fade-out-up'}`}
            style={{ display: isInView ? 'flex' : 'none' }}
        >
            <div className="flex items-center gap-2">
                <p className="p1Responsive text-black-100">🤔 Curious about me?</p>
                <p className="p1Responsive hidden text-black-100 sm:block"> Let the AI answer your questions!</p>
            </div>
            <AnchorLink href={aiBioLink} target="_blank" className="buttonText flex w-fit items-center  space-x-2 rounded-[8px]  bg-gradient-to-r  from-[#E1AFD1] to-[#7469B6] px-3 py-2 text-center md:px-4 md:py-3 ">
                <p className="font-urbanist text-base normal-case tracking-[0.48px] text-white">Ask with AI </p>
                <Star width={22} height={22} />
            </AnchorLink>
        </div>
    );
}
