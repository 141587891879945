import InfoMessage from '@app/components/icons/info-message';
import ImageRenderer from '@app/components/media-renderer/image-renderer';
import useIntersectionObserver from '@app/lib/hooks/use-intersection-observer';
import useIsMobile from '@app/lib/hooks/use-isMobile';
import { TestimonialDto } from '@app/models/dtos/infoDto';
import cn from 'classnames';
import { useState } from 'react';

function Testimonials({ testimonials }: { testimonials: Array<TestimonialDto> }) {
    const { isVisible, ref } = useIntersectionObserver({ threshold: 0.3 });
    const { isMobile } = useIsMobile();
    const [currentTestimonial, setCurrentTestimonial] = useState(testimonials[0]);
    const testimonialImageMargin = (index: number) => {
        switch (index) {
            case 0: {
                return '-mr-10';
            }
            case 1: {
                return '-mr-10 -ml-10';
            }
            case 2: {
                return '-ml-10';
            }
            default: {
                return 'm-0';
            }
        }
    };
    return (
        <div ref={ref} className={cn('relative  flex w-full flex-col rounded-[8px]  bg-testimonial md:h-[430px]', isVisible && 'in-view', 'animated-component')}>
            <div className="flex w-full  flex-col gap-12 p-6 md:p-12">
                <div>
                    <p className="pResponsive w-full max-w-[460px] text-black-200">{currentTestimonial.thought}</p>
                    <div className="mt-12 flex flex-col gap-2">
                        <p className="h3 text-black-100">{currentTestimonial.name}</p>
                        <p className="p2 text-black-200">{currentTestimonial.position}</p>
                    </div>
                </div>
            </div>
            <div className="bottom-0 right-[19px] flex w-full max-w-[682px] items-end justify-center md:absolute">
                {testimonials.map((testimonial, index) => {
                    return (
                        <div
                            onClick={() => {
                                setCurrentTestimonial(testimonial);
                            }}
                            key={testimonial.image}
                            className={cn('flex   h-[160px] cursor-pointer flex-col items-center gap-4 md:mb-0 md:h-[198px]', testimonial.image === currentTestimonial.image && `!z-10 !h-[221px] md:!h-[312px] ${testimonialImageMargin(index)}`)}
                        >
                            {testimonial.image !== currentTestimonial.image && <InfoMessage className="top-10 sm:absolute" />}
                            <ImageRenderer className={cn('opacity-40', testimonial.image === currentTestimonial.image && `!opacity-100 `)} src={testimonial.image} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Testimonials;
