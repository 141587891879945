import React from 'react';

function Star(props: React.SVGAttributes<{}>) {
    return (
        <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M13.1153 6.9032C13.1741 8.48461 12.906 10.0612 12.3277 11.5342C11.7494 13.0073 10.8733 14.3451 9.75431 15.4641C8.63531 16.5832 7.29743 17.4592 5.82438 18.0375C4.35132 18.6158 2.77478 18.884 1.19336 18.8251C2.77478 18.7663 4.35132 19.0344 5.82438 19.6127C7.29743 20.191 8.63531 21.0671 9.75431 22.1861C10.8733 23.3051 11.7494 24.643 12.3277 26.116C12.906 27.5891 13.1741 29.1656 13.1153 30.747C13.0564 29.1656 13.3246 27.5891 13.9029 26.116C14.4812 24.643 15.3572 23.3051 16.4762 22.1861C17.5952 21.0671 18.9331 20.191 20.4062 19.6127C21.8792 19.0344 23.4558 18.7663 25.0372 18.8251C23.4558 18.884 21.8792 18.6158 20.4062 18.0375C18.9331 17.4592 17.5952 16.5832 16.4762 15.4641C15.3572 14.3451 14.4812 13.0073 13.9029 11.5342C13.3246 10.0612 13.0564 8.48461 13.1153 6.9032Z"
                fill="white"
            />
            <path
                d="M25.2229 1.47534C25.2537 2.30334 25.1133 3.12879 24.8105 3.90005C24.5077 4.67131 24.0491 5.37179 23.4632 5.95768C22.8773 6.54357 22.1768 7.00225 21.4055 7.30504C20.6343 7.60784 19.8088 7.74822 18.9808 7.71741C19.8088 7.6866 20.6343 7.82699 21.4055 8.12978C22.1768 8.43257 22.8773 8.89125 23.4632 9.47714C24.0491 10.063 24.5077 10.7635 24.8105 11.5348C25.1133 12.306 25.2537 13.1315 25.2229 13.9595C25.1921 13.1315 25.3325 12.306 25.6353 11.5348C25.9381 10.7635 26.3967 10.063 26.9826 9.47714C27.5685 8.89126 28.269 8.43257 29.0403 8.12978C29.8115 7.82699 30.637 7.6866 31.465 7.71741C30.637 7.74822 29.8115 7.60783 29.0403 7.30504C28.269 7.00225 27.5685 6.54356 26.9826 5.95768C26.3967 5.37179 25.9381 4.67131 25.6353 3.90004C25.3325 3.12878 25.1921 2.30334 25.2229 1.47534Z"
                fill="white"
            />
        </svg>
    );
}

export default Star;
