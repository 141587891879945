import React from 'react';

function BlinkingEllipse(props: React.SVGAttributes<{}>) {
    return (
        <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="4" cy="4.5" r="4" fill="#13DE70">
                <animate attributeName="opacity" dur="1s" values="1;0;1" repeatCount="indefinite" begin="0s" />
            </circle>
        </svg>
    );
}

export default BlinkingEllipse;
