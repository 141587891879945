import AIBioTopBar from '@app/components/AIBioTopBar';
import AboutMeCard from '@app/components/cards/aboutMeCard';
import ContactCard from '@app/components/cards/contactCard';
import HobbyCard from '@app/components/cards/hobbyCard';
import PersonalitiesCard from '@app/components/cards/personalitiesCard';
import ProfileCard from '@app/components/cards/profileCard';
import QuoteCard from '@app/components/cards/quoteCard';
import SocialProviderCard from '@app/components/cards/socialProviderCard';
import Testimonials from '@app/components/cards/testimonials';
import Product from '@app/components/product';
import Layout from '@app/layouts/_layout';
import useIntersectionObserver from '@app/lib/hooks/use-intersection-observer';
import { InfoDto } from '@app/models/dtos/infoDto';
import { selectRandomItem } from '@app/utils/random';
import cn from 'classnames';

export default function Home({ infoJson }: { infoJson: InfoDto }) {
    const infoObserver = useIntersectionObserver({ threshold: 0.3 });

    return (
        <Layout profileDetails={infoJson.profileDetails}>
            <main className={` flex flex-col items-center justify-center gap-[80px] md:gap-[120px]`}>
                <AIBioTopBar />

                <div className="grid grid-cols-1 gap-5 md:grid-cols-3 lg:flex">
                    <ProfileCard profileDetails={infoJson.profileDetails} />
                    <QuoteCard quote={infoJson.quote} />
                    <PersonalitiesCard personalities={infoJson.personalities} />
                </div>

                <div className={cn('flex flex-col gap-12')}>
                    {infoJson.products.map((product, index) => {
                        return <Product className={index > 0 ? 'animated-component' : ''} key={product.title} product={product} />;
                    })}
                </div>

                <Testimonials testimonials={infoJson.testimonials} />

                <div ref={infoObserver.ref} className={cn('grid grid-cols-1  gap-5 md:grid-cols-3 lg:flex', infoObserver.isVisible && 'in-view', 'animated-component')}>
                    <AboutMeCard profileDetails={infoJson.profileDetails} />
                    <HobbyCard profileDetails={infoJson.profileDetails} />
                    <SocialProviderCard socialProviders={infoJson.socialProviders} profileDetails={infoJson.profileDetails} />
                </div>

                <ContactCard profileDetails={infoJson.profileDetails} facts={infoJson.facts} />
            </main>
        </Layout>
    );
}

export async function getServerSideProps() {
    const infoResponse = await fetch('https://nabinkawan.github.io/bucket/portfolio/info.json');
    if (infoResponse.status === 200) {
        const infoJson = await infoResponse.json();
        return {
            props: {
                infoJson: { ...infoJson, quote: selectRandomItem(infoJson.quotes) }
            }
        };
    }
    return {
        notFound: true
    };
}
