import Arrow from '@app/components/icons/arrow';
import Chill from '@app/components/icons/chill';
import AnchorLink from '@app/components/ui/links/anchor-link';
import useIsMobile from '@app/lib/hooks/use-isMobile';
import { ProfileDetailsDto } from '@app/models/dtos/infoDto';

function Navbar({ profileDetails }: { profileDetails: ProfileDetailsDto }) {
    const { isMobile } = useIsMobile();

    const mailLink = isMobile ? `mailto:${profileDetails.email}` : `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${profileDetails.email}`;
    return (
        <nav className="!z-30  w-full  ">
            <div className="topBar mx-[15px] flex h-[60px] items-center justify-between rounded-[4px] bg-white bg-opacity-50 px-3 backdrop-blur-[11px] sm:mx-5 md:h-[82px] lg:mx-10 lg:px-6">
                <div className="flex items-center gap-2">
                    <Chill />
                    <p className="h2Responsive text-black-100">{profileDetails.name}</p>
                </div>
                <AnchorLink target={'_blank'} href={mailLink} className="group flex items-center gap-1 md:gap-2 ">
                    <p className="p1Responsive text-black-100 group-hover:text-blue">
                        <span className="block sm:hidden">Contact Me</span>
                        <span className="hidden sm:block">{profileDetails.email}</span>
                    </p>
                    <Arrow className="h-[10px] w-[11px] text-black-100 group-hover:text-blue sm:h-[16px] sm:w-[18px]" />
                </AnchorLink>
            </div>
        </nav>
    );
}

export default Navbar;
