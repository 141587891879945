import ImageRenderer from '@app/components/media-renderer/image-renderer';
import useIntersectionObserver from '@app/lib/hooks/use-intersection-observer';
import useIsMobile from '@app/lib/hooks/use-isMobile';
import { PersonalitiesDto } from '@app/models/dtos/infoDto';
import cn from 'classnames';

function PersonalitiesCard({ personalities }: { personalities: PersonalitiesDto }) {
    const { isVisible, ref } = useIntersectionObserver({ threshold: 0.3 });
    const { isMobile } = useIsMobile();
    return (
        <div ref={ref} className={cn('flex h-[312px] w-full flex-col items-center gap-6 rounded-[8px] bg-[#F7F5FF] p-6 sm:items-start md:h-auto lg:h-[430px] lg:max-w-[314px]', isVisible && 'in-view', isMobile && 'animated-component-top-down')}>
            <div className="h-[94px] w-[94px]">
                <ImageRenderer src={personalities.image} />
            </div>
            <div>
                <p className="h2Responsive text-brown">{personalities.title}</p>
                <p className="p1Responsive mb-2 mt-1 text-black-200">{personalities.description}</p>
                <p className="p2 text-black-200">- {personalities.references}</p>
            </div>
        </div>
    );
}

export default PersonalitiesCard;
